import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`Welcome to Hybrit!`}</p>
    <p>{`This is the Hybrit dev docs. Here you will find everything you need to start development at Hybrit`}</p>
    <h2 {...{
      "id": "quicklinks"
    }}>{`quicklinks`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/src-getting-started-index"
        }}>{`Gettings Started`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      